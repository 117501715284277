import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
import { getView, setSelectedNames, getTplMap } from '@/utils/viewinfo';
export default {
  name: "region",
  props: {
    zone: String,
    zoneStatus: Number,
    regionRow: {
      type: Object,
      default: function _default() {
        return {
          names: [],
          labels: [],
          lines: [],
          targets: [],
          hc: {
            tplName: '',
            tplId: 0
          }
        };
      }
    },
    isDefault: Boolean,
    idx: Number,
    isPrimaryDomain: {
      type: Boolean,
      default: false
    },
    recorderType: Array,
    isPtrZone: Boolean
  },
  data: function data() {
    return {
      regionChooseVisible: false,
      chooseValues: [],
      chooseLabels: [],
      props: {
        multiple: true,
        qZone: this.zone
      },
      options: [],
      priorityOptions: [{
        label: this.$t('region.priority-primary'),
        value: 0
      }, {
        label: this.$t('region.priority-secondary'),
        value: 1
      }],
      hcTplMap: new Map(),
      chooseHcTplName: '',
      chooseHcTplId: 0,
      tplData: {},
      typePhMap: {
        "A": "98.98.98.98",
        "AAAA": "2001:0db8::8a2e:0370:bab5",
        "CNAME": "www.example.com",
        "PTR": "www.example.com",
        "MX": "10 mailserver.example.com",
        // "TXT": "",
        "NS": "ns1.example.com",
        "SRV": "1 10 80 www.example.com",
        "CAA": "0 issue \"caa.exampleca.com\"",
        "SPF": "v=spf1 ip4:192.168.0.1/16-all",
        "NAPTR": "10 100 \"S\" \"SIP+D2U\" \"\" foo.example.com"
      }
    };
  },
  filters: {
    formatType: function formatType(type) {
      if (type == 1) {
        return 'http';
      } else if (type == 2) {
        return 'https';
      } else if (type == 3) {
        return 'tcp';
      } else if (type == 4) {
        return 'ping';
      } else {
        return '';
      }
    }
  },
  methods: {
    handleRecorderType: function handleRecorderType(val, rowData) {
      rowData.valueTip = this.typePhMap[val] || this.$t('region.dlg-input-value');
      rowData.value = '';
      if (val == 'A' || val == 'AAAA') {
        // rowData.weight = ''
        rowData.isHc = true;
      } else {
        // rowData.weight = ''
        rowData.isHc = false;
      }
    },
    handleTplChange: function handleTplChange(val) {
      if (val == '') {
        this.chooseHcTplName = '';
        this.chooseHcTplId = 0;
        this.tplData = {};
        this.regionRow.targets.forEach(function (target) {
          target.priority = 0;
        });
      } else {
        var tplObj = this.hcTplMap.get(val);
        this.chooseHcTplName = tplObj.tplName;
        this.chooseHcTplId = val;
        this.tplData = tplObj;
      }
    },
    handleCommand: function handleCommand(command) {
      if (command == 'set') {
        this.chooseView();
      } else if (command == 'del') {
        this.removeRegion();
      }
    },
    handleChange: function handleChange() {
      var _this = this;
      var nodes = this.$refs['cascader'].getCheckedNodes(true);
      this.chooseLabels = [];
      nodes.forEach(function (node) {
        var newLabels = [];
        node.pathLabels.forEach(function (label) {
          newLabels.push(label);
        });
        _this.chooseLabels.push(newLabels.join('/'));
      });
    },
    removeRegion: function removeRegion() {
      this.$emit('delRegion', this.idx);
    },
    addRow: function addRow() {
      this.regionRow.targets.push({
        type: "",
        value: "",
        ttl: 600,
        // extend:10,
        weight: "",
        priority: 0,
        isHc: true,
        valueTip: this.$t('region.dlg-input-value')
      });
    },
    minusRow: function minusRow(index) {
      this.regionRow.targets.splice(index, 1);
    },
    closeDialog: function closeDialog() {
      this.regionChooseVisible = false;
    },
    saveChooseView: function saveChooseView() {
      if (!this.isDefault) {
        var names = [];
        this.chooseValues.forEach(function (nameArr) {
          names.push(nameArr[nameArr.length - 1]);
        });
        var removeNames = [];
        this.regionRow.names.forEach(function (name) {
          if (names.indexOf(name) == -1) {
            removeNames.push(name);
          }
        });
        this.regionRow.names = names;
        setSelectedNames(names, removeNames);
        this.regionRow.lines = this.chooseValues;
        this.regionRow.labels = this.chooseLabels;
      }
      this.regionRow.hc.tplName = this.chooseHcTplName;
      this.regionRow.hc.tplId = this.chooseHcTplId;
      this.closeDialog();
    },
    chooseView: function chooseView() {
      if (!this.isDefault) {
        this.options = getView(this.regionRow.names, this.zone);
        this.chooseValues = this.regionRow.lines;
        this.chooseLabels = this.regionRow.labels;
      }
      this.chooseHcTplName = this.regionRow.hc.tplName;
      this.chooseHcTplId = this.regionRow.hc.tplId;

      // 模板
      this.hcTplMap = getTplMap();
      var tplObj = this.hcTplMap.get(this.chooseHcTplId);
      if (tplObj) {
        this.tplData = tplObj;
      } else {
        this.tplData = {};
      }
      this.regionChooseVisible = true;
    }
  },
  created: function created() {}
};