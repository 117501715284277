var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "recorder-group-div"
  }, [_c("div", {
    staticClass: "recorder-group-close"
  }, [_c("el-dropdown", {
    on: {
      command: _vm.handleCommand
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_c("i", {
    staticClass: "el-icon-more btn-record-more"
  })]), _c("el-dropdown-menu", {
    staticClass: "popper-dropdown",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-s-tools",
      disabled: _vm.isPtrZone,
      command: "set"
    }
  }, [_vm._v(_vm._s(_vm.$t("region.setting-btn")))]), _c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-remove",
      disabled: _vm.isDefault,
      command: "del"
    }
  }, [_vm._v(_vm._s(_vm.$t("region.delete-btn")))])], 1)], 1)], 1), _c("div", {
    staticClass: "recorder-group-recorder"
  }, [_vm._l(_vm.regionRow.targets, function (rowData, index) {
    return _c("el-row", {
      key: index
    }, [_c("el-col", {
      attrs: {
        span: 1
      }
    }, [index == 0 ? _c("el-button", {
      staticClass: "btn-record-opr",
      attrs: {
        type: "primary",
        size: "mini",
        icon: "el-icon-plus",
        plain: "",
        circle: ""
      },
      on: {
        click: _vm.addRow
      }
    }) : _vm._e(), index > 0 ? _c("el-button", {
      staticClass: "btn-record-opr",
      attrs: {
        type: "danger",
        size: "mini",
        icon: "el-icon-minus",
        disabled: _vm.isPrimaryDomain && rowData.type == "NS",
        plain: "",
        circle: ""
      },
      on: {
        click: function click($event) {
          return _vm.minusRow(index);
        }
      }
    }) : _vm._e()], 1), _c("el-col", {
      attrs: {
        span: 4
      }
    }, [_c("el-select", {
      attrs: {
        placeholder: _vm.$t("region.dlg-select-recordtype"),
        disabled: _vm.isPrimaryDomain && rowData.type == "NS"
      },
      on: {
        change: function change(val) {
          _vm.handleRecorderType(val, rowData);
        }
      },
      model: {
        value: rowData.type,
        callback: function callback($$v) {
          _vm.$set(rowData, "type", $$v);
        },
        expression: "rowData.type"
      }
    }, _vm._l(_vm.recorderType, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: {
          label: item.value,
          value: item.value
        }
      }, [_c("span", {
        staticClass: "opt-value"
      }, [_vm._v(_vm._s(item.value))]), item.value == "SPF" ? _c("span", {
        staticClass: "opt-value"
      }, [_vm._v("（" + _vm._s(_vm.$t("region.recordtype-annotation-notsuggest")) + "）")]) : _vm._e(), _c("span", {
        staticClass: "opt-label"
      }, [_vm._v("-" + _vm._s(item.label))])]);
    }), 1)], 1), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-input", {
      attrs: {
        clearable: "",
        placeholder: rowData.valueTip,
        disabled: _vm.isPrimaryDomain && rowData.type == "NS"
      },
      model: {
        value: rowData.value,
        callback: function callback($$v) {
          _vm.$set(rowData, "value", $$v);
        },
        expression: "rowData.value"
      }
    })], 1), _c("el-col", {
      attrs: {
        span: 3
      }
    }, [_c("el-tooltip", {
      attrs: {
        content: _vm.$t("region.input-weight-tip"),
        placement: "top",
        effect: "light",
        "hide-after": 0,
        "open-delay": 500
      }
    }, [_c("el-input", {
      attrs: {
        clearable: "",
        placeholder: _vm.$t("region.dlg-input-weight"),
        disabled: rowData.type != "" && rowData.type != "A"
      },
      model: {
        value: rowData.weight,
        callback: function callback($$v) {
          _vm.$set(rowData, "weight", _vm._n($$v));
        },
        expression: "rowData.weight"
      }
    })], 1)], 1), _c("el-col", {
      attrs: {
        span: 3
      }
    }, [_c("el-tooltip", {
      attrs: {
        content: _vm.$t("region.input-ttl-tip"),
        placement: "top",
        effect: "light",
        "hide-after": 800,
        "open-delay": 500
      }
    }, [_c("el-input", {
      attrs: {
        clearable: "",
        placeholder: _vm.$t("region.dlg-input-ttl"),
        disabled: _vm.isPrimaryDomain && rowData.type == "NS"
      },
      model: {
        value: rowData.ttl,
        callback: function callback($$v) {
          _vm.$set(rowData, "ttl", _vm._n($$v));
        },
        expression: "rowData.ttl"
      }
    })], 1)], 1), _vm.regionRow.hc.tplName && _vm.regionRow.hc.tplName != "" ? _c("el-col", {
      attrs: {
        span: 3
      }
    }, [_c("el-select", {
      attrs: {
        placeholder: _vm.$t("region.dlg-select-priority"),
        disabled: rowData.type != "A" && rowData.type != "CNAME" && rowData.type != "AAAA"
      },
      model: {
        value: rowData.priority,
        callback: function callback($$v) {
          _vm.$set(rowData, "priority", $$v);
        },
        expression: "rowData.priority"
      }
    }, _vm._l(_vm.priorityOptions, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: {
          label: item.label,
          value: item.value
        }
      });
    }), 1)], 1) : _vm._e(), _vm.regionRow.hc.tplName && _vm.regionRow.hc.tplName != "" ? _c("el-col", {
      attrs: {
        span: 1
      }
    }, [_c("el-tooltip", {
      attrs: {
        content: _vm.$t("region.sw-hc-tip"),
        placement: "top",
        effect: "light",
        "hide-after": 800,
        "open-delay": 500
      }
    }, [_c("el-switch", {
      attrs: {
        width: 30,
        "active-color": "#13ce66",
        disabled: rowData.type != "A" && rowData.type != "AAAA"
      },
      model: {
        value: rowData.isHc,
        callback: function callback($$v) {
          _vm.$set(rowData, "isHc", $$v);
        },
        expression: "rowData.isHc"
      }
    })], 1)], 1) : _vm._e(), _c("el-col", {
      staticClass: "task-error",
      attrs: {
        span: 2
      }
    }, [_c("el-tooltip", {
      attrs: {
        content: _vm.$t("region.task-failed-tip"),
        placement: "top",
        effect: "light",
        "hide-after": 800,
        "open-delay": 500
      }
    }, [rowData.isHc && rowData.status == 2 ? _c("span", [_c("img", {
      staticClass: "ip_down",
      attrs: {
        src: require("@/assets/alarm.png"),
        alt: _vm.$t("region.task-failed-tip"),
        title: _vm.$t("region.task-failed-tip")
      }
    })]) : _vm._e()])], 1)], 1);
  }), _vm.regionRow.hc.tplName && _vm.regionRow.hc.tplName != "" || _vm.regionRow.labels && _vm.regionRow.labels.length > 0 ? _c("div", [_vm.regionRow.labels && _vm.regionRow.labels.length > 0 ? _c("div", {
    staticClass: "recorder-group-recorder-label-item"
  }, _vm._l(_vm.regionRow.labels, function (item) {
    return _c("el-tag", {
      key: item,
      staticClass: "recorder-group-choosed-tag",
      attrs: {
        size: "mini",
        type: _vm.isDefault ? "info" : "",
        effect: "dark"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1) : _vm._e(), _c("div", {
    staticClass: "recorder-group-recorder-label-item"
  }, [_vm.regionRow.hc.tplName && _vm.regionRow.hc.tplName != "" ? _c("el-tag", {
    staticClass: "recorder-group-choosed-tag",
    attrs: {
      size: "mini",
      type: "danger",
      effect: "dark"
    }
  }, [_vm._v(" " + _vm._s(_vm.regionRow.hc.tplName) + " ")]) : _vm._e()], 1)]) : _vm._e()], 2), _c("el-dialog", {
    staticClass: "recorder-region-dlg",
    attrs: {
      title: _vm.$t("region.setting-dlg-title"),
      visible: _vm.regionChooseVisible,
      width: "55%",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.regionChooseVisible = $event;
      }
    }
  }, [_c("div", [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [!_vm.isDefault ? _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("region.dlg-tab-region")
    }
  }, [_c("el-cascader-panel", {
    ref: "cascader",
    staticClass: "recorder-group-panel",
    attrs: {
      props: _vm.props,
      options: _vm.options
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.chooseValues,
      callback: function callback($$v) {
        _vm.chooseValues = $$v;
      },
      expression: "chooseValues"
    }
  })], 1) : _vm._e(), _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("region.dlg-tab-hc")
    }
  }, [_vm.zoneStatus == 2 ? _c("i", {
    staticClass: "recorder-region-tpl-tip"
  }, [_vm._v(_vm._s(_vm.$t("region.template-stop-tip")))]) : _c("i", {
    staticClass: "recorder-region-tpl-tip"
  }, [_vm._v(_vm._s(_vm.$t("region.template-select-tip")))]), _vm.zoneStatus != 2 ? _c("el-select", {
    staticClass: "choose-tpl",
    attrs: {
      filterable: "",
      clearable: "",
      placeholder: _vm.$t("region.dlg-select-template")
    },
    on: {
      change: _vm.handleTplChange
    },
    model: {
      value: _vm.chooseHcTplName,
      callback: function callback($$v) {
        _vm.chooseHcTplName = $$v;
      },
      expression: "chooseHcTplName"
    }
  }, _vm._l(_vm.hcTplMap, function (value) {
    return _c("el-option", {
      key: value[0],
      attrs: {
        label: value[1].tplName,
        value: value[0]
      }
    });
  }), 1) : _vm._e(), _vm.chooseHcTplName != "" ? _c("div", {
    staticClass: "recorder-region-tpl-card"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("region.card-hc-title")))])]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(_vm._s(_vm.$t("region.hc-label-name") + _vm.tplData.tplName))]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(_vm._s(_vm.$t("region.hc-label-type"))), _c("el-tag", {
    attrs: {
      effect: "dark",
      size: "mini"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatType")(_vm.tplData.type)))])], 1), _c("div", {
    staticClass: "text item"
  }, [_vm._v(_vm._s(_vm.$t("region.hc-label-host") + _vm.tplData.hostName))]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(_vm._s(_vm.$t("region.hc-label-port") + _vm.tplData.port))]), _c("div", {
    staticClass: "text item"
  }, [_vm._v(_vm._s(_vm.$t("region.hc-label-url") + _vm.tplData.url))])])], 1) : _vm._e()], 1)], 1)], 1), _vm.chooseHcTplName != "" || _vm.chooseLabels.length > 0 ? _c("div", {
    staticClass: "recorder-group-choosed-div"
  }, [_c("div", [_c("el-divider", {
    attrs: {
      "content-position": "left"
    }
  }), _c("label", [_vm._v(_vm._s(_vm.$t("region.label-choosed")))])], 1), _c("div", {
    staticClass: "recorder-group-choosed-item"
  }, _vm._l(_vm.chooseLabels, function (item) {
    return _c("el-tag", {
      key: item,
      staticClass: "recorder-group-choosed-tag",
      attrs: {
        size: "mini",
        effect: "dark"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 1), _c("div", {
    staticClass: "recorder-group-choosed-item"
  }, [_vm.chooseHcTplName != "" ? _c("el-tag", {
    staticClass: "recorder-group-choosed-tag",
    attrs: {
      size: "mini",
      type: "danger",
      effect: "dark"
    }
  }, [_vm._v(" " + _vm._s(_vm.chooseHcTplName) + " ")]) : _vm._e()], 1)]) : _vm._e(), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.saveChooseView
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };